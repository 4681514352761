<template>
  <span
    class="starWrapper"
    v-b-tooltip.hover.top.v-light="`${$t('property.reviewSource')}`"
  >
    <star-rating
      :rating="parseFloat(starValue)"
      :increment="0.01"
      :fixed-points="1"
      :star-size="14"
      read-only
    />
  </span>
</template>

<script>
import StarRating from "vue-star-rating";
export default {
  components: {
    StarRating,
  },
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    total: {
      type: Number,
      default: 5,
    },
    content: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    starValue() {
      if (this.value !== null) return this.value;
      if (!this.content.length) return "0";
      return (
        this.content.reduce((total, record) => total + record.rating, 0) /
        this.content.length
      );
    },
  },
};
</script>

<style>
.starWrapper {
  cursor: pointer;
}
.vue-star-rating-rating-text {
  display: none !important;
}
</style>
